
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
export default class extends Vue
{
    @Prop({type:Array,required:true})
    headers!:Array<any>;

    @Prop({type:Array,required:true})
    items!:Array<any>;
}
